.main-container__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'PT Sans Narrow', sans-serif;
  color: #fff;
  font-weight: 700;
  margin: 15px 0;
  position: absolute;
  width: 100%;
  bottom: 0;
}

@media screen and (max-width: 476px) {
  .main-container__footer {
    gap: 10px;
  }
}

@media screen and (max-width: 394px) {
  .main-container__footer {
    gap: 5px;
  }
}
