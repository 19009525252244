.main-containe__player {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
  margin: 10px 0;
}

.main-containe__player .main-containe__player-image-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.main-containe__player
  .main-containe__player-image-container
  .main-containe__player-image-container__artwork {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.main-containe__player
  .main-containe__player-image-container
  .main-containe__player-image-container__btn {
  position: absolute;
  cursor: pointer;
  background: #ff6f61;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  z-index: 1;
  bottom: -50px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.main-containe__player
  .main-containe__player-image-container
  .main-containe__player-image-container__btn::before,
.main-containe__player
  .main-containe__player-image-container
  .main-containe__player-image-container__btn::after {
  content: '';
  position: absolute;
  height: 80px;
  width: 80px;
  background-color: #ff6f61;
  border-radius: 50%;
  z-index: -1;
  opacity: 0.7;
  border: 0;
}

.main-containe__player
  .main-containe__player-image-container
  .main-containe__player-image-container__btn::before {
  animation: pulse 2s ease-out infinite;
}

.main-containe__player
  .main-containe__player-image-container
  .main-containe__player-image-container__btn::after {
  animation: pulse 2s 1s ease-out infinite;
}

@keyframes pulse {
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

.main-containe__player .main-containe__player-now-playing-container {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.main-containe__player
  .main-containe__player-now-playing-container
  .main-containe__player-now-playing-container__text {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 400;
  color: #fff;
  margin: 0;
  font-size: 18px;
}

.main-containe__player
  .main-containe__player-now-playing-container
  .main-containe__player-now-playing-container__artist {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700;
  color: #fff;
  margin: 0;
  font-size: 36px;
}

.main-containe__player
  .main-containe__player-now-playing-container
  .main-containe__player-now-playing-container__title {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700;
  color: #ff6f61;
  margin: 0;
  font-size: 24px;
}

.main-containe__player .main-containe__player-next-playing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: relative;
  max-width: 1280px;
  width: 100%;
}

.main-containe__player
  .main-containe__player-next-playing-container
  .main-containe__player-next-playing-container__text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-containe__player
  .main-containe__player-next-playing-container
  .main-containe__player-next-playing-container__text-container
  .main-containe__player-next-playing-container__text-container__text {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #87878a;
  margin: 0;
  text-transform: uppercase;
}

.main-containe__player
  .main-containe__player-next-playing-container
  .main-containe__player-next-playing-container__text-container
  .main-containe__player-next-playing-container__text-container__song {
  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #87878a;
  margin: 0;
}

.main-containe__player
  .main-containe__player-next-playing-container
  .main-container__social-icons {
  position: absolute;
  right: 0;
}

#canvas {
  position: absolute;
  z-index: -1;
  opacity: 0.3;
}

@media screen and (max-width: 1681px) {
  .main-containe__player {
    gap: 10px;
  }
  .main-containe__player .main-containe__player-image-container {
    height: 250px;
  }
  .main-containe__player
    .main-containe__player-image-container
    .main-containe__player-image-container__artwork {
    height: 250px;
    width: 250px;
  }
  .main-containe__player
    .main-containe__player-now-playing-container
    .main-containe__player-now-playing-container__artist {
    font-size: 30px;
  }
}

@media screen and (max-width: 1439px) {
  .main-containe__player .main-containe__player-image-container {
    height: 200px;
  }
  .main-containe__player
    .main-containe__player-image-container
    .main-containe__player-image-container__artwork {
    height: 200px;
    width: 200px;
  }
  .main-containe__player
    .main-containe__player-now-playing-container
    .main-containe__player-now-playing-container__artist {
    font-size: 26px;
  }
}

@media screen and (max-width: 476px) {
  .main-containe__player .main-containe__player-image-container {
    height: 250px;
  }
  .main-containe__player
    .main-containe__player-image-container
    .main-containe__player-image-container__artwork {
    width: 250px;
    height: 250px;
  }
  .main-containe__player
    .main-containe__player-now-playing-container
    .main-containe__player-now-playing-container__artist {
    font-size: 24px;
  }
  .main-containe__player
    .main-containe__player-now-playing-container
    .main-containe__player-now-playing-container__title {
    font-size: 20px;
  }
}

@media screen and (max-width: 394px) {
  .main-containe__player .main-containe__player-image-container {
    height: 200px;
  }
  .main-containe__player
    .main-containe__player-image-container
    .main-containe__player-image-container__artwork {
    width: 200px;
    height: 200px;
  }
}

@media screen and (max-width: 361px) {
  .main-containe__player
    .main-containe__player-now-playing-container
    .main-containe__player-now-playing-container__artist {
    font-size: 20px;
  }
  .main-containe__player .main-containe__player-next-playing-container {
    display: none;
  }
}
