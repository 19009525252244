.main-container__header {
  font-family: 'PT Sans Narrow', sans-serif;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 30px 0 0 0;
}

.main-container__header .main-container__header-title {
  color: #fff;
  font-size: 60px;
  margin: 0;
}

.main-container__header .main-container__header-subtitle {
  color: #ff6f61;
  font-size: 25px;
  margin: 0;
}

@media screen and (max-width: 1681px) {
  .main-container__header .main-container__header-title {
    font-size: 50px;
  }
}

@media screen and (max-width: 1439px) {
  .main-container__header {
    margin: 20px 0 0 0;
  }
  .main-container__header .main-container__header-title {
    font-size: 40px;
  }
  .main-container__header .main-container__header-subtitle {
    font-size: 20px;
  }
}

@media screen and (max-width: 394px) {
  .main-container__header .main-container__header-title {
    font-size: 30px;
  }
  .main-container__header .main-container__header-subtitle {
    font-size: 16px;
  }
}
