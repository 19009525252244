@import url('https://fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #0e0e14;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.main-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  position: relative;
  height: 100vh;
}

.main-container__loading-text {
  font-family: 'PT Sans Narrow', sans-serif;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
}

@media screen and (max-width: 1439px) {
  body {
    max-width: 1100px;
  }
  .main-container {
    gap: 10px;
  }
}
